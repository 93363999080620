<template>
    <div>
    <div class="mobile pt25">

        <div class="m_profile p_modal" @click="openMemberModal"></div>
        <div  class="m_item">
            <div class="m_title">가입</div>
            <div class="m_content">2020-05-30 11:11</div>
        </div>
        <div  class="m_item">
            <div class="m_title">아이디</div>
            <div class="m_content">papamiso</div>
        </div>
        <div  class="m_item">
            <div class="m_title">이름</div>
            <div class="m_content">유이름</div>
        </div>
        <div  class="m_item">
            <div class="m_title">생년월일</div>
            <div class="m_content">2000.11.11</div>
        </div>
        <div  class="m_item">
            <div class="m_title">이메일</div>
            <div class="m_content">toystory@gmail.com</div>
        </div>
        <div  class="m_item">
            <div class="m_title">전화번호</div>
            <div class="m_content">010-1234-5678</div>
        </div>
        <input type="textarea" placeholder="자기소개 입력" rows="2"  class="m_textarea">
        </input>
        <div class="m_title pb5">자기소개</div>
    </div>
    <div class="m_guard">
        <div class="mg_title">보호자정보</div>
        <div  class="m_item">
            <div class="m_title">아이디</div>
            <div class="m_content">papamiso</div>
        </div>
        <div  class="m_item">
            <div class="m_title">이름</div>
            <div class="m_content">유이름</div>
        </div>
        <div  class="m_item">
            <div class="m_title">생년월일</div>
            <div class="m_content">2000.11.11</div>
        </div>
    </div>
    </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";

    export default {
        name: "MemberDetailPageMenteeLayout",
        mixins: [],
        components: {
        },
        props: {
            memberData: {
                type: Object,
                default: () => {return {}},
            },
        },
        data() {
            return {

            }
        },
        beforeRouterEnter() {},
        created() {

        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {

        },
        methods:{
            openMemberModal() {
                EventBus.$emit('openMemberInfoModal')
            }
        },
        watch: {

        },
    }
</script>

<style scoped>

</style>